import React, { Component } from "react";
import "../css/Auction.css"; // Create a separate CSS file for styling
import LogoHeader from "./LogoHeader";
import Spinner from "../Spinner";
import AuctionItemForm from "./AuctionForm";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import the CSS for styling
import EditAuctionItemForm from "./EditAuctionForm";
import up_arrow from '../img/up_arrow.png';
import down_arrow from '../img/down_arrow.png';
import remove from '../img/remove.png';
import eye from '../img/eye.png';
import live from '../img/live.png';
import draft from '../img/draft.png';
import edit from '../img/edit.png';

const NEW_ITEM = {
  ExternalURL: "",
  OfferingType: "",
  ListingName: "",
  DonorName: "",
  ListingNumber: "",
  MinimumBid: 1,
  MinimumBiddingIncrement: 1,
  MonetaryValue: 0,
  TitleOfMonetaryValue: "",
  ItemDescription: "",
  Tags: [],
  SendTaxReceipt: "No",
  BuyNowPrice: 0,
  Image1: null,
  Image2: null,
  Image3: null,
  Image4: null,
  Image5: null,
  Image6: null,
  Image7: null,
  Image8: null,
  EmailAttachment: null,
  PickupInstructions: "",
  AdditionalFees: 0,
};

class Auction extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      newItem: NEW_ITEM,
      editedItem: NEW_ITEM,
      OfferingTypes: ["Silent Auction", "Live Auction"],
      MonetaryValueTitles: [
        "Retail Value",
        "Value",
        "Estimated Value",
        "Priceless",
      ],
      SingleChoices: ["Yes", "No"],
      error: null,
      screenWidth: window.innerWidth,
      isLoading: false,
      existingTags: [],
      expandedRowID: null
    };

    this.addItem = this.addItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this.fetchItems();
    this.fetchExistingTags();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  fetchExistingTags = () => {
    return new Promise((resolve, reject) => {
      try {
        fetch(`${process.env.REACT_APP_API_URL}/brenda-legacy/tags`)
          .then((response) => response.json())
          .then((data) => {
            this.setState({ existingTags: data }, () => {
              resolve(); // Resolve the promise after setState is completed
            });
          })
          .catch((error) => {
            console.error("Error fetching tags:", error);
            reject(error); // Reject the promise if there's an error
          });
      } catch (error) {
        console.error("Error fetching tags:", error);
        reject(error); // Reject the promise if there's an error
      }
    });
  };

  async fetchItems() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/brenda-legacy/auction-items`
      );
      const data = await response.json();
      this.setState({ items: data });
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  }

  hasEmptyFields(item) {
    for (const key in item) {
      if (item.hasOwnProperty(key)) {
        if (item[key] === "" || item[key] === null) {
          if (
            key === "ListingName" ||
            key === "DonorName" ||
            key === "ListingNumber" ||
            key === "MinimumBid" ||
            key === "MinimumBiddingIncrement" ||
            key === "MonetaryValue" ||
            key === "TitleOfMonetaryValue" ||
            key === "ItemDescription"
          ) {
            return true; // Found an empty field
          }
        }
      }
    }
    return false; // No empty fields found
  }

  addItem (e) {
    e.preventDefault();
    this.setState({ isLoading: true });

    const { newItem, items } = this.state;

    if (this.hasEmptyFields(newItem)) {
      this.setState({ error: "Missing required fields", isLoading: false });
      return;
    }

    fetch(`${process.env.REACT_APP_API_URL}/brenda-legacy/auction-items`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newItem),
    })
      .then((response) => {
        if (response.ok) {
          const updatedItems = [...items, newItem];
          this.setState({
            items: updatedItems,
            newItem: NEW_ITEM,
          });
          toast.success("Item added!");

                  // Make GET request after successfully adding the item
        fetch(`${process.env.REACT_APP_API_URL}/titan-legacy/new-item/communicate`, {
          method: "GET"
        }).then(data => {
          this.fetchItems();
        });
        } else {
          response.json().then((data) => {
            console.log(data);
            this.setState({ error: data["Message"] });
          });
        }
      })
      .catch((error) => {
        this.setState({ error: `Error adding item: ${error.message}` });
        console.error("Error adding item:", error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  async updateItem(e) {
    e.preventDefault();
    this.setState({ isLoading: true });

    const { editedItem } = this.state;

    if (this.hasEmptyFields(editedItem)) {
      this.setState({ error: "Missing required fields", isLoading: false });
      return;
    }

    fetch(`${process.env.REACT_APP_API_URL}/brenda-legacy/auction-items`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(editedItem),
    })
      .then((response) => {
        if (response.ok) {
          this.fetchItems();
          toast.success("Item updated!");
          this.setState({expandedRowID: null});
        } else {
          response.json().then((data) => {
            console.log(data);
            this.setState({ error: data["Message"] });
          });
        }
      })
      .catch((error) => {
        this.setState({ error: `Error adding item: ${error.message}` });
        console.error("Error adding item:", error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  handleResize() {
    this.setState({ screenWidth: window.innerWidth });
  }

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default form submission
      // Handle any other logic you want to perform when Enter is pressed in this field
    }
  };

  handleItemUpdate = (e) => {
    const { id, value } = e.target;
    const updatedNewItem = { ...this.state.newItem, [id]: value };
    this.setState({ newItem: updatedNewItem });
  };

  handleEditorChange = (content, id) => {
    const updatedNewItem = { ...this.state.newItem, [id]: content };

    this.setState({ newItem: updatedNewItem }, () => {
      console.log(this.state.newItem);
    });
  };

  handleEditItemUpdate = (e) => {
    const { id, value } = e.target;
    const updatedEditItem = { ...this.state.editedItem, [id]: value };
    this.setState({ editedItem: updatedEditItem });
  };

  handleDeleteImage = (e, image) => {
    e.preventDefault();
    const updatedEditItem = { ...this.state.editedItem, [image]: null };
    this.setState({ editedItem: updatedEditItem });
  };

  handleEditEditorChange = (content, id) => {
    const updatedEditItem = { ...this.state.editedItem, [id]: content };

    this.setState({ editedItem: updatedEditItem });
  };


  handleFileUpdate = async (e) => {
    const { id } = e.target;
    const file = e.target.files[0];

    const uniqueCode = Date.now().toString().substring(6); // Using a substring of current timestamp

    // Replace spaces in filename with underscores
    let filename = file.name.replace(/\s/g, '_');

    // Remove accented characters
    filename = filename.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    const filenameWithCode = `${uniqueCode}_${filename}`;

    await this.handleFileChange(file, filenameWithCode);
    const updatedItem = { ...this.state.newItem, [id]: filenameWithCode };
    this.setState({ newItem: updatedItem }, () => {
      console.log(this.state.newItem);
    });
  };

  handleEditFileUpdate = async (e) => {
    const { id } = e.target;
    const file = e.target.files[0];

    const uniqueCode = Date.now().toString().substring(6); // Using a substring of current timestamp

    // Replace spaces in filename with underscores
    let filename = file.name.replace(/\s/g, '_');

    // Remove accented characters
    filename = filename.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

   const filenameWithCode = `${uniqueCode}_${filename}`;

    await this.handleFileChange(file, filenameWithCode);
    const updatedItem = { ...this.state.editedItem, [id]: filenameWithCode };
    this.setState({ editedItem: updatedItem }, () => {
      console.log(this.state.editedItem);
    });
  };

  handleTagUpdate = async (e) => {
    const tagID = e.target.value;

    if (tagID === "-1") {
      const noTagsItem = { ...this.state.newItem, Tags: [] };

      this.setState({ newItem: noTagsItem });
      return;
    }

    // Check if tagID already exists in newItem.Tags
    const tagIndex = this.state.newItem.Tags.indexOf(tagID);

    // If tagID exists, remove it; otherwise, add it
    let updatedTags;
    if (tagIndex !== -1) {
      updatedTags = this.state.newItem.Tags.filter((id) => id !== tagID);
    } else {
      updatedTags = [...this.state.newItem.Tags, tagID];
    }

    // Update the newItem object with the updated tags
    const updatedItem = { ...this.state.newItem, Tags: updatedTags };

    // Update state with the modified newItem
    this.setState({ newItem: updatedItem });
  };



  handleEditTagUpdate = async (e) => {
    const tagID = e.target.value;

    if (tagID === "-1") {
      const noTagsItem = { ...this.state.editedItem, Tags: [] };

      this.setState({ editedItem: noTagsItem });
      return;
    }

    // Check if tagID already exists in newItem.Tags
    const tagIndex = this.state.editedItem.Tags.indexOf(tagID);

    // If tagID exists, remove it; otherwise, add it
    let updatedTags;
    if (tagIndex !== -1) {
      updatedTags = this.state.editedItem.Tags.filter((id) => id !== tagID);
    } else {
      updatedTags = [...this.state.editedItem.Tags, tagID];
    }

    // Update the newItem object with the updated tags
    const updatedItem = { ...this.state.editedItem, Tags: updatedTags };

    // Update state with the modified newItem
    this.setState({ editedItem: updatedItem });
  };

  

  
  handlePromoteStatus = async (event, id) => {
    event.preventDefault();

    // Replace 'your-api-endpoint' with the actual API endpoint
    const apiUrl = `${process.env.REACT_APP_API_URL}/brenda-legacy/auction-items/increment/${id}`;

    try {
      // Make a PUT request to mark the wine as picked up
      await axios.get(apiUrl);
      // Handle a successful response, e.g., show a success message
      console.log("Status updated successfully");
      await this.fetchItems();
    } catch (error) {
      // Handle errors, e.g., show an error message
      console.error("Error updating status", error);
    }
  };

  handleDemoteStatus = async (event, id) => {
    event.preventDefault();

    // Replace 'your-api-endpoint' with the actual API endpoint
    const apiUrl = `${process.env.REACT_APP_API_URL}/brenda-legacy/auction-items/decrease/${id}`;

    try {
      // Make a PUT request to mark the wine as picked up
      await axios.get(apiUrl);
      // Handle a successful response, e.g., show a success message
      console.log("Status updated successfully");
      await this.fetchItems();
    } catch (error) {
      // Handle errors, e.g., show an error message
      console.error("Error updating status", error);
    }
  };

  handleDelete = (event, id) => {
    event.preventDefault();

    confirmAlert({
      title: "Confirm Deletion",
      message: "Are you sure you want to delete this auction item?",
      buttons: [
        {
          label: "Yes",
          className: "green-button", // Apply custom class to 'No' button
          onClick: async () => {
            const apiUrl = `${process.env.REACT_APP_API_URL}/brenda-legacy/auction-items?item_id=${id}`;

            try {
              await axios.delete(apiUrl);

              await this.fetchItems();
            } catch (error) {
              console.error("Error deleting task", error);
            }
          },
        },
        {
          label: "No",
          className: "red-button", // Apply custom class to 'No' button
          onClick: () => console.log("Deletion canceled by user"),
        },
      ],
    });
  };

  handleLive = (event, id, status) => {
    event.preventDefault();
    const message = !status
      ? "Are you sure you want to return this item to draft?"
      : "Are you sure you want to make this auction item live?";

    confirmAlert({
      title: "Confirm Live",
      message: message,
      buttons: [
        {
          label: "Yes",
          className: "green-button", // Apply custom class to 'No' button
          onClick: async () => {
            const apiUrl = `${process.env.REACT_APP_API_URL}/brenda-legacy/auction-items/live/${id}`;

            try {
              await axios.post(apiUrl, { isLive: status });

              await this.fetchItems();
            } catch (error) {
              console.error("Error making item live", error);
            }
          },
        },
        {
          label: "No",
          className: "red-button", // Apply custom class to 'No' button
          onClick: () => console.log("live canceled by user"),
        },
      ],
    });
  };

  handleFileChange = async (file, filename) => {
    this.setState({ isLoading: true });
    const requestBody = {
      filename: filename,
    };

    try {
      // Call the API endpoint to get the file upload details
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/brenda-legacy/auction-items/file`,
        requestBody
      );

      const { url, fields } = response.data;
      const tempUrl = url;

      // Prepare form data with the file to be uploaded
      const formData = new FormData();
      Object.entries({ ...fields, file }).forEach(([key, value]) => {
        formData.append(key, value);
      });

      // Perform the file upload using the obtained URL and fields
      await axios.post(tempUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      this.setState({ filename: filename }, () => {
        toast.success("File upload successfully!");
      });
    } catch (error) {
      // Handle error scenarios
      console.error("Error uploading file:", error);

      toast.error("File upload failed!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    this.setState({ isLoading: false });
  };

  getTagByID = (ID) => {
    return this.state.existingTags.find(
      (tag) => tag.ID.toString() === ID.toString()
    );
  };

  updateExpandRow = (e, ID) => {
    e.preventDefault();
  
    // Check if the incoming ID is the same as the current expandedRowID
    if (this.state.expandedRowID !== null && this.state.expandedRowID === ID) {
      // If it is, set expandedRowID to null
      this.setState({
        expandedRowID: null,
        editedItem: null, // Optionally reset editedItem as well
      });
    } else {
      // Assuming this.state.receiptList and this.state.searchList are arrays of items
      const itemListToSearch = [...this.state.items];
  
      // Find the item with matching receiptID
      const foundItem = itemListToSearch.find(item => item.ID === ID);
  
      if (foundItem) {
        // Update receiptInfo with the found item
        this.setState({
          expandedRowID: ID,
          editedItem: foundItem,
        });
      }
    }
  };
  

  render() {
    const {
      items,
      newItem,
      error,
      isLoading,
      screenWidth,
      OfferingTypes,
      MonetaryValueTitles,
      SingleChoices,
      existingTags,
    } = this.state;
    const isIPad = screenWidth <= 1200;

    return (
      <>
        <LogoHeader />
        <main>
          <AuctionItemForm
            newItem={newItem}
            error={error}
            isLoading={isLoading}
            addItem={this.addItem}
            OfferingTypes={OfferingTypes}
            handleItemUpdate={this.handleItemUpdate}
            MonetaryValueTitles={MonetaryValueTitles}
            handleEditorChange={this.handleEditorChange}
            SingleChoices={SingleChoices}
            handleFileUpdate={this.handleFileUpdate}
            handleTagUpdate={this.handleTagUpdate}
            toast={toast}
            existingTags={existingTags}
            fetchExistingTags={this.fetchExistingTags}
          />
          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>List of Auction Items</span>
            </h1>
            <div className="legend">
            <div className="legend-item"> 
            <img src={up_arrow} alt="Up Arrow" />
            <span>Shifts the auction item up in order</span>
            </div>

            <div className="legend-item">
              <img src={down_arrow} alt="Down Arrow" />
              <span>Shifts the auction item down in order</span>
            </div>
            <div className="legend-item">
              <img src={remove} alt="Remove" />
              <span>Removes the auction item</span>
            </div>
            <div className="legend-item">
              <img src={eye} alt="Eye" />
              <span>Previews the auction item</span>
            </div>
            <div className="legend-item">
              <img src={live} alt="Live" />
              <span>Makes the auction item live</span>
            </div>
            <div className="legend-item">
              <img src={draft} alt="Draft" />
              <span>Reverts the auction item to draft status</span>
            </div>
            <div className="legend-item">
              <img src={edit} alt="Edit" />
              <span>Edits the auction item</span>
            </div>

          </div>
            <div className="cart-items-wrap">
              <table className="cart-items">
                <thead>
                  <tr>
                  <td className="cart-del" style={{backgroundColor: "#2dccd3"}}></td>
                  <td className="cart-del" style={{backgroundColor: "#2dccd3"}}></td>
                    <th className="id-column" style={{ textAlign: "center" }}>Order Number</th>
                    <th style={{ textAlign: "center" }}>Listing Number</th>
                    <th style={{ textAlign: "center" }}>Listing Name</th>
                    <th style={{ textAlign: "center" }}>Status</th>


                    <td className="cart-del" style={{backgroundColor: "#2dccd3"}}></td>
                    <td className="cart-del" style={{backgroundColor: "#2dccd3"}}></td>
                    <td className="cart-del" style={{backgroundColor: "#2dccd3"}}></td>
                    <td className="cart-del" style={{backgroundColor: "#2dccd3"}}></td>
                  </tr>
                </thead>
                <tbody>
                  {items
                    .sort((a, b) =>  a.Order - b.Order) // Sort items array by the Order column in ascending order
                    .map((item, index, array) => (
                      <>
                      <tr key={item.ID} className="id-column">
                      <td className="cart-del">
                          {index !== 0 && (
                            <a
                              className="cart-paid"
                              href="/"
                              onClick={(e) => {
                                e.preventDefault(); // Prevent the default link behavior
                                this.handleDemoteStatus(e, item.ID);
                              }}
                              role="button" // Add role="button" to indicate it's a button
                            >
                              {"\u00A0"}
                            </a>
                          )}
                        </td>
                        <td className="cart-del">
                          {index !== array.length - 1 && (
                            <a
                              className="cart-pickedup"
                              href="/"
                              onClick={(e) => {
                                e.preventDefault(); // Prevent the default link behavior
                                this.handlePromoteStatus(e, item.ID);
                              }}
                              role="button" // Add role="button" to indicate it's a button
                            >
                              {"\u00A0"}
                            </a>
                          )}
                        </td>

                        <td style={{ textAlign: "center" }}>{index + 1}</td>

                        <td style={{ textAlign: "center" }}>
                          {item.ListingNumber}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {item.ListingName}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {item.isLive ? (
                            <span style={{ color: "red" }}>LIVE</span>
                          ) : (
                            "Preview Mode"
                          )}
                        </td>

                        <td className="cart-del">
                          <a
                            className="cart-remove"
                            href="/"
                            onClick={(e) => {
                              e.preventDefault(); // Prevent the default link behavior
                              this.handleDelete(e, item.ID);
                            }}
                            role="button" // Add role="button" to indicate it's a button
                          >
                            {"\u00A0"}
                          </a>
                        </td>

                        <td className="cart-del">
                          <a
                            className="cart-preview"
                            href={`https://www.preview.auction.bigsisters.bc.ca/item-detail-one?item_id=${item.ID}`} // Update the href attribute to point to Google.com
                            role="button" // Add role="button" to indicate it's a button
                            target="_blank"
                          >
                            {"\u00A0"}
                          </a>
                        </td>

                        {item.isLive ? (
                          <td className="cart-del">
                            <a
                              className="cart-draft"
                              href="/"
                              onClick={(e) => {
                                e.preventDefault(); // Prevent the default link behavior
                                this.handleLive(e, item.ID, false);
                              }}
                              role="button" // Add role="button" to indicate it's a button
                            >
                              {"\u00A0"}
                            </a>
                          </td>
                        ) : (
                          <td className="cart-del">
                            <a
                              className="cart-live"
                              href="/"
                              onClick={(e) => {
                                e.preventDefault(); // Prevent the default link behavior
                                this.handleLive(e, item.ID, true);
                              }}
                              role="button" // Add role="button" to indicate it's a button
                            >
                              {"\u00A0"}
                            </a>
                          </td>
                          
                        )}
                                                <td className="cart-del">
                            <a
                              className="cart-edit"
                              href="/"
                              onClick={(e) => {
                                this.updateExpandRow(e, item.ID);
                              }}
                              role="button" // Add role="button" to indicate it's a button
                            >
                              {"\u00A0"}
                            </a>
                          </td>
                      </tr>

                      {this.state.expandedRowID === item.ID && (
                    <>
                      <tr className="notes-row">
                        <td colSpan="10">
                              <EditAuctionItemForm
                                editedItem={this.state.editedItem}
                                error={error}
                                isLoading={isLoading}
                                updateItem={this.updateItem}
                                OfferingTypes={OfferingTypes}
                                handleItemUpdate={this.handleEditItemUpdate}
                                MonetaryValueTitles={MonetaryValueTitles}
                                handleEditorChange={this.handleEditEditorChange}
                                SingleChoices={SingleChoices}
                                handleFileUpdate={this.handleEditFileUpdate}
                                handleTagUpdate={this.handleEditTagUpdate}
                                toast={toast}
                                existingTags={existingTags}
                                fetchExistingTags={this.fetchExistingTags}
                                handleDeleteImage={this.handleDeleteImage}
                              />
        
                        </td>
                      </tr>
                    </>
                  )}
                      </>

                      
                    ))}

                </tbody>
              </table>
            </div>
          </section>
          {isLoading && <Spinner />}
        </main>
        <ToastContainer />
      </>
    );
  }
}

export default Auction;