import React, { Component } from "react";
import "../css/Attendee.css"; // Create a separate CSS file for styling
import LogoHeader from "./LogoHeader";
import Handshake from "../img/handshake.png";
import Spinner from "../Spinner";
import Form from "react-bootstrap/Form";

class AuctionRegistration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      attendees: [],
      bidders: [],
      error: null,
      screenWidth: window.innerWidth,
      isLoading: false,
      categories: [
        { name: "Registered Bidders", value: "bidders" },
        { name: "List of Voucher", value: "vouchers" },
      ],
      category: "bidders",
    };

    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this.fetchBidders();
    this.fetchVouchers();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  async fetchVouchers() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/titan-legacy/bidders`);
      let data = await response.json();

      data = data.filter((attendee) => attendee.attendee_id !== 328);

      data = data.filter((attendee) => attendee.ID !== 32);

      this.setState({ attendees: data }, () => console.log(this.state));
    } catch (error) {
      console.error("Error fetching attendees:", error);
    }
  }

  async fetchBidders() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/titan-legacy/users`);
      let data = await response.json();

      this.setState({ bidders: data["all_users_details"] }, () =>
        console.log(this.state)
      );
    } catch (error) {
      console.error("Error fetching attendees:", error);
    }
  }

  handleResize() {
    this.setState({ screenWidth: window.innerWidth });
  }

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default form submission
      // Handle any other logic you want to perform when Enter is pressed in this field
    }
  };

  handleOptionChange = (e) => {
    const selectedValue = e.target.value;
    this.setState({ category: selectedValue });

    console.log(selectedValue);
  };

  render() {
    const { attendees, screenWidth } = this.state;
    const isIPad = screenWidth <= 1200;

    return (
      <>
        <LogoHeader />
        <main>
          <section className="container stylization maincont">
            <div className="auth-wrap">
              <div className="auth-col">
                <form className="form-validate">
                  <p className="contactform-field contactform-text">
                    <label className="contactform-label">Categories</label>
                    <span className="contactform-input">
                      <Form.Control
                        id="amount"
                        as="select"
                        value={this.state.category}
                        onChange={this.handleOptionChange}
                      >
                        <option
                          value=""
                          disabled
                          hidden
                          style={{ color: "#999" }}
                        >
                          &#x2015; Choose an option &#x2015;
                        </option>
                        {this.state.categories.map((category) => (
                          <option key={category.value} value={category.value}>
                            {category.name}
                          </option>
                        ))}
                      </Form.Control>
                    </span>
                  </p>
                </form>
                {this.state.error && (
                  <div className="err404">
                    <p className="err404-search">{this.state.error}</p>
                  </div>
                )}
              </div>
            </div>
          </section>

          {this.state.category === "bidders" && (
            <section className="container stylization maincont">
              <h1 className="main-ttl">
                <span>List of Registered Bidders</span>
              </h1>
              <div className="cart-items-wrap">
                <table className="cart-items">
                  <thead>
                    <tr>
                      <td className="cart-image"></td>
                      <td className="cart-ttl">Display Name</td>
                      <td className="cart-ttl">Bidder Contact Information</td>
                      <td className="cart-quantity">
                        Has Bidder Confirmed Their Account?
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.bidders
                      .slice()
                      .filter(
                        (result) => {
                          return !result.email.endsWith("@bigsistersbc.ca");
                        }

                        
                      )
                      .map((result, index) => (
                        <tr key={index}>
                          <td className="cart-image">
                            <a href="product.html">
                              <img src={Handshake} alt="Handshake" />
                            </a>
                          </td>
                          <td className="cart-ttl">{result["nickname"]}</td>
                          <td className="cart-ttl">
                            {result["phone_number"]}
                            <br />
                            {result["email"]}
                          </td>
                          <td
                            className="cart-quantity"
                            style={{
                              color:
                                result["ConfirmationStatus"] === "CONFIRMED"
                                  ? "green"
                                  : "red",
                            }}
                          >
                            {result["ConfirmationStatus"] === "CONFIRMED"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </section>
          )}

          {this.state.category === "vouchers" && (
            <section className="container stylization maincont">
              <h1 className="main-ttl">
                <span>List of Vouchers</span>
              </h1>
              <div className="cart-items-wrap">
                <table className="cart-items">
                  <thead>
                    <tr>
                      <td className="cart-image"></td>
                      <td className="cart-ttl">Voucher Emailed to</td>
                      <td className="cart-ttl">
                        Ticket Purchaser Contact Information
                      </td>
                      <td className="cart-quantity">Has Voucher Been Used?</td>
                      <td className="cart-quantity">Voucher Code</td>
                    </tr>
                  </thead>
                  <tbody>
                    {attendees
                      .slice()
                      .sort((a, b) => {
                        // Sort by alreadyUsed, placing used vouchers first
                        if (b.alreadyUsed !== a.alreadyUsed) {
                          return b.alreadyUsed - a.alreadyUsed;
                        } else {
                          // If both vouchers are used or unused, sort by ID in descending order
                          return b.ID - a.ID;
                        }
                      })
                      .filter((result) => {
                        return (
                          !result.code.startsWith("kids_") &&
                          !result.code.startsWith("seal_") &&
                          !result.code.startsWith("museum_") &&
                          !result.code.startsWith("Wilson_")
                        );
                      })
                      .map((result, index) => (
                        <tr key={index}>
                          <td className="cart-image">
                            <a href="product.html">
                              <img src={Handshake} alt="Handshake" />
                            </a>
                          </td>
                          <td className="cart-ttl">
                            {result["voucher_salutation"]}
                            <br />
                            <br />
                            {result["voucher_email"]}
                          </td>
                          <td className="cart-ttl">
                            {result["attendee_firstname"]}{" "}
                            {result["attendee_lastname"]}{" "}
                            {result["isPrimary"] === 1 && (
                              <span style={{ color: "#fcb61c" }}>
                                [PRIMARY]
                              </span>
                            )}
                            <br />
                            <br />
                            {result["address_line_1"]}
                            <br />
                            {result["address_line_2"] && (
                              <>
                                {result["address_line_2"]}
                                <br />
                              </>
                            )}
                            {result["city"]}, {result["province"]}{" "}
                            {result["postal_code"]}
                            <br />
                            {result["phone_number"]}
                            <br />
                            {result["email"]}
                          </td>
                          <td className="cart-quantity">
                            {result["alreadyUsed"] === 1 ? "Yes" : "No"}
                          </td>
                          <td className="cart-quantity">
                            <a
                              href={`https://auction.bigsisters.bc.ca/signup?promo_code=${result["code"]}`}
                              target="_blank"
                            >
                              {result["code"]}
                            </a>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </section>
          )}

          {this.state.isLoading && <Spinner />}
        </main>
      </>
    );
  }
}

export default AuctionRegistration;