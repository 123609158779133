import React, { Component } from "react";
import AuraButton from "./AuraButton";
import "./css/DashboardHub.css"; // Assuming you have a separate CSS file for styling
import LogoHeader from "./LogoHeader";

class DashboardHub extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
      <LogoHeader />

        <main className="add-pledges">
          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>Event Data</span>&nbsp;[Staff ONLY]
            </h1>

            <div className="auth-wrap">
              <div className="auth-col">
                <form onSubmit={this.handleSubmit} className="form-validate">
                  <h5 style={{ fontWeight: "bold" }}>Soirée 2024</h5>
                  <div className="contactform-field contactform-textarea">
                    Upcoming event
                    <br />
                    <AuraButton label="Soirée 2024" link="https://aura.bigsistersbc.ca" />
                  </div>

                  <h5 style={{ fontWeight: "bold" }}>GrapeJuice 2024</h5>
                  <div className="contactform-field contactform-textarea">
                    Past event
                    <br />
                    <AuraButton label="GrapeJuice 2024" link="/grapejuice2024" />
                  </div>


                  <h5 style={{ fontWeight: "bold" }}>Soirée 2023</h5>
                  <div className="contactform-field contactform-textarea">
                  Past event
                    <br />
                    <AuraButton label="Soirée 2023" link="soiree2023" />
                  </div>
                </form>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}

export default DashboardHub;