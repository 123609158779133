import React, { Component } from "react";
import axios from "axios"; // Import Axios for HTTP requests
import LogoHeader from "./LogoHeader"; // Import LogoHeader or specify the correct import path
import "../css/Pledges.css"; // Create a separate CSS file for styling
import Spinner from "../Spinner";

class Raffle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      display: "",
      error: "",
      isLoading: false
    };
  }

  updateDisplay = async () => {
    try {
      // Define the API endpoint and the data you want to send
      const apiUrl = `${process.env.REACT_APP_API_URL}/brenda-legacy/raffles`; // Replace with your actual API URL
      const requestData = {
        text: this.state.display, // Use this.state.display to get the display text from your component's state
      };

      // Make the HTTP POST request to update the display
      const response = await axios.post(apiUrl, requestData);

      // Check the response status or data to determine success
      if (response.status === 200) {
        return true; // Successful update
      } else {
        return false; // Update failed
      }
    } catch (error) {
      console.error('Error updating display:', error);
      return false; // Update failed due to an error
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true, error: "" }); // Set isLoading and error to initial values
  
    try {
      const result = await this.updateDisplay();
  
      if (result) {
        this.setState({
          display: "",
          isLoading: false
        });
      } else {
        this.setState({ error: "Failed to update raffle number, please try again." });
      }
    } catch (error) {
      console.error("An error occurred:", error);
      this.setState({ error: "An error occurred, please try again.", isLoading: false });
    }
  };
  

  render() {
    return (
      <>
        <LogoHeader /> {/* You should specify the correct import or component */}
        <main>
          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>Display Raffle</span>
            </h1>

            <div className="auth-wrap">
              <div className="auth-col">
                <form onSubmit={this.handleSubmit} className="form-validate">
                  <p>
                    <label htmlFor="display">
                      Display Text <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="display"
                      name="Display"
                      value={this.state.display}
                      onChange={(e) => this.setState({ display: e.target.value })}
                    />
                  </p>

                  <p className="auth-submit">
                    <input type="submit" value="Update Display" /> {/* Updated button label */}
                  </p>
                </form>
                {this.state.error && (
                  <div className="err404">
                    <p className="err404-search">{this.state.error}</p>
                  </div>
                )}
              </div>
            </div>
          </section>
          {this.state.isLoading && (<Spinner />)}
        </main>
      </>
    );
  }
}

export default Raffle;
