import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import "../css/Pledges.css"; // Create a separate CSS file for styling
import LogoHeader from "./LogoHeader";
import Handshake from "../img/handshake.png";
import Spinner from "../Spinner";
import axios from "axios";

class Pledges extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ids: "",
      donationType: "",
      customAmount: "",
      dollarAmount: "",
      error: null,
      searchResults: [],
      data: [],
      isLoading: false,
      categories: []
    };
  }

  generateUniqueId = () => {
    const timestamp = new Date().getTime();
    const random = Math.floor(Math.random() * 1000000);

    const barcodeId = `${timestamp}${random}`;

    return barcodeId;
  };

  fetchCategories = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/brenda-legacy/pledgeCategories`)
      .then((response) => response.json())
      .then((dataList) => {
        // Check if dataList is an array
        if (Array.isArray(dataList)) {
          if (dataList.length !== 0) {
            let myDataList = [...dataList];

            this.setState({ categories: myDataList });
          }
        }
      });
  };

  fetchData = () => {
    let uniqueId = sessionStorage.getItem("uniqueId");
    if (!uniqueId) {
      uniqueId = this.generateUniqueId();
      sessionStorage.setItem("uniqueId", uniqueId);
    }

    fetch(`${process.env.REACT_APP_API_URL}/brenda-legacy/bids?uniqueId=${uniqueId}`)
      .then((response) => response.json())
      .then((dataList) => {

        if (Array.isArray(dataList)) {
          if (dataList.length !== 0) {
            let myDataList = [...dataList];
            myDataList.sort((a, b) => b.Amount - a.Amount);

            this.setState({ searchResults: myDataList }, ()=> {
              console.log(this.state);
            });
          }
        }
      });
  };

  componentDidMount() {
    this.setState({isLoading:true}, ()=> {
      this.fetchCategories();
      this.fetchData();
      this.setState({isLoading: false});
    })

  }

  handleDonationChange = (e) => {
    const selectedValue = e.target.value;
    this.setState({ dollarAmount: selectedValue, donationType: selectedValue });

    console.log(selectedValue);

    if (selectedValue === "custom") {
      this.setState({ customAmount: "" });
    }
  };

  getIds = () => {
    const idList = this.state.ids
      .split("\n")
      .map((id) => id.trim())
      .filter((id) => id !== "")
      .reverse();

    return idList;
  };

  addPledge = async (id_list) => {
    let amount = 0.0;

    if (id_list.length > 0) {
      if (this.state.donationType === "custom") {
        amount = this.state.customAmount;
      } else {
        amount = this.state.dollarAmount;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/brenda-legacy/bids`, {
          method: "POST",
          body: JSON.stringify({
            Identifiers: id_list,
            Amount: amount,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        return true;
      } catch (err) {
        throw new Error("Try submitting pledges again");
      }
    } 
    
    throw new Error("Missing either Paddle Numbers or QR Codes");

  };

  handleDelete = (event, pledgeId) => {
    event.preventDefault();

    fetch(`${process.env.REACT_APP_API_URL}/brenda-legacy/bids?pledgeId=${pledgeId}`, {
      method: "DELETE", 
      headers: {
        "Content-Type": "application/json"
      },
    })
      .then((response) => {
        if (response.ok) {
          const mySearchResults = [...this.state.searchResults];
          const updatedItems = mySearchResults.filter(
            (item) => item.ID !== pledgeId
          );
          this.setState({ searchResults: updatedItems });
        } else {
          console.error("Failed to delete item.");
        }
      })
      .catch((error) => {
        console.error("Error while deleting item:", error);
      });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true, error: "" }, async () => {
      const id_list = this.getIds();
  
      if (id_list.length === 0) {
        this.setState({ error: "Please enter a paddle number or a QR code", isLoading: false });
      } else if (this.state.dollarAmount === "") {
        this.setState({ error: "Please select a pledge amount", isLoading: false });
      } else {
        try {
          const result = await this.addPledge(id_list);
          if (result) {
            this.setState({
              ids: "",
              dollarAmount: "",
              donationType: "",
              isLoading: false,
              error: ""
            }, () => {
              this.fetchData();
            });
          }
        } catch (error) {
          console.error("Error adding result:", error);
          this.setState({ isLoading: false, error: "Something went wrong, try submitting again!" });
        }
      }
    });
  };
  

  convertToPST = (timestampString) => {
    const date = new Date(timestampString);
  
    if (isNaN(date.getTime())) {
      return "Invalid Time";
    }
  
    const offsetMinutes = date.getTimezoneOffset();
    const offsetMilliseconds = offsetMinutes * 60 * 1000;
  
    const pstTime = new Date(date - offsetMilliseconds);
    
    const pstTimeString = pstTime.toLocaleString("en-US", {
      timeZone: "America/Los_Angeles",
    });
  
    return pstTimeString;
  };
  

  handlePaid = async (event, bidID) => {
    event.preventDefault();

    // Replace 'your-api-endpoint' with the actual API endpoint
    const apiUrl = `${process.env.REACT_APP_API_URL}/brenda-legacy/bids`;

    const requestBody = {
      ID: bidID
    };

    try {
      // Make a PUT request to mark the wine as picked up
      await axios.put(apiUrl, requestBody);
      // Handle a successful response, e.g., show a success message
      console.log("Bid paid successfully");
      await this.fetchData();
    } catch (error) {
      // Handle errors, e.g., show an error message
      console.error("Error paying bid", error);
    }
};

  render() {
    return (
      <>
        <LogoHeader />
        <main>
          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>Add Pledges</span>
            </h1>

            <div className="auth-wrap">
              <div className="auth-col">
                <form onSubmit={this.handleSubmit} className="form-validate">
                  <p className="contactform-field contactform-textarea">
                    <label className="contactform-label">
                      Paddle Numbers or QR Codes
                    </label>
                    <span className="contactform-input">
                      <textarea
                        placeholder="Please enter a list of values, such as Paddle Numbers or QR Codes, with each value separated by a newline"
                        name="mess"
                        data-required="text"
                        value={this.state.ids}
                        onChange={(e) => this.setState({ ids: e.target.value })}
                      />
                    </span>
                  </p>
                  <p className="contactform-field contactform-text">
                    <label className="contactform-label">Pledge Amount</label>
                    <span className="contactform-input">
                      <Form.Control
                        id="amount"
                        as="select"
                        value={this.state.donationType}
                        onChange={this.handleDonationChange}
                      >
                        <option
                          value=""
                          disabled
                          hidden
                          style={{ color: "#999" }}
                        >
                          &#x2015; Choose an option or set a default amount
                          &#x2015;
                        </option>
                        {this.state.categories.map(category => (
                            <option key={category.amount} value={category.amount}>
                              {category.name}
                            </option>))}   
                        <option key="custom" value="custom">Custom Amount</option>
                      </Form.Control>
                    </span>
                  </p>

                  {this.state.donationType === "custom" && (
                    <p className="contactform-field contactform-text">
                      <label className="contactform-label"></label>
                      <span className="contactform-input">
                        <input
                          type="text"
                          placeholder="Enter Custom Dollar Amount"
                          value={this.state.customAmount}
                          onChange={(e) =>
                            this.setState({ customAmount: e.target.value })
                          }
                          required // Required only when custom donation type is selected
                        />
                      </span>
                    </p>
                  )}

                  <p className="auth-submit">
                    <input type="submit" value="Add Pledge" />
                  </p>
                </form>
                {this.state.error && (
                  <div className="err404">
                    <p className="err404-search">{this.state.error}</p>
                  </div>
                )}
              </div>
            </div>
          </section>
          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>List of Pledges</span>
            </h1>
            {this.state.searchResults.length > 0 && (
              <div className="cart-items-wrap">
                <table className="cart-items">
                  <thead>
                    <tr>
                      <td className="cart-image"></td>
                      <td className="cart-ttl">Name</td>
                      <td className="cart-quantity">Amount</td>
                      <td className="cart-quantity">Paddle Number</td>
                      <td className="cart-quantity">Table Number</td>
                      <td className="cart-price">Date Added</td>
                      <td className="cart-del">&nbsp;</td>
                      <td className="cart-del">&nbsp;</td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.searchResults
                      .slice() // Create a shallow copy of the array to avoid mutating the original
                      .sort(
                        (a, b) => new Date(b.DateAdded) - new Date(a.DateAdded)
                      ) // Sort by the 'Added' field in descending order
                      .map((result, index) => (
                        <tr key={index}>
                          <td className="cart-image">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault(); // Prevent the default link behavior
                                this.handleDelete(e, result.ID);
                              }}
                            >
                              <img src={Handshake} alt="Handshake" />
                            </a>
                          </td>
                          <td className="cart-ttl">{result.Name}</td>
                          <td className="cart-quantity">
                            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(result.Amount)}<br/>
                            <span style={{ color: result["isPaid"] ? '#0ba011' : '#e82131' }}>
                              {result["isPaid"] ? '[Paid]' : '[Not Paid]'}
                            </span>
                          </td>


                          <td className="cart-quantity">
                            {result["Paddle Number"]}
                          </td>
                          <td className="cart-quantity">
                            {result["Table Number"]}
                          </td>
                          <td className="cart-price">
                            {this.convertToPST(result.DateAdded)}
                          </td>
                          <td className="cart-del">
                            <a
                              className="cart-paid"
                              href="/"
                              onClick={(e) => {
                                e.preventDefault(); // Prevent the default link behavior
                                this.handlePaid(e, result.ID);
                              }}
                              role="button" // Add role="button" to indicate it's a button
                            >
                              {"\u00A0"}
                            </a>
                          </td>
                          <td className="cart-del">
                            <a
                              className="cart-remove"
                              href="/"
                              onClick={(e) => {
                                e.preventDefault(); // Prevent the default link behavior
                                this.handleDelete(e, result.ID);
                              }}
                              role="button" // Add role="button" to indicate it's a button
                            >
                              {"\u00A0"}
                            </a>
                          </td>

                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </section>
          {this.state.isLoading && (<Spinner />)}
        </main>
      </>
    );
  }
}

export default Pledges;