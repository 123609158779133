import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../img/logo.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';
import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons";
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { faTicket } from "@fortawesome/free-solid-svg-icons";
import { faDisplay } from "@fortawesome/free-solid-svg-icons";
import { faWineBottle } from "@fortawesome/free-solid-svg-icons";
import { faReceipt } from "@fortawesome/free-solid-svg-icons";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";

function LogoHeader() {
  const navigate = useNavigate();
  const location = useLocation();

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set("prevUrl", location.pathname);
      navigate(`/login?${currentUrl.searchParams}`);
    }
  }, [token, location, navigate]);

  const handleLogout = async (e) => {
    e.preventDefault();
    localStorage.setItem("token", "");

    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set("prevUrl", location.pathname);
    navigate(`/login?${currentUrl.searchParams}`);
  };


  return (
    <header className="header">
    <div className="header-middle">
      <div className="container header-middle-cont">
        <div className="toplogo">
          <a href="/soiree2023">
            <img src={logo} alt="Big Sisters of BC Lower Mainland" />
          </a>
        </div>
        <div className="shop-menu social-icons">
          <ul>
            <li>
              <a href="/soiree2023">
              <FontAwesomeIcon icon={faDisplay} />&nbsp;
                <span className="shop-menu-ttl">Pledges Display</span>
              </a>
            </li>
            <li>
              <a href="/soiree2023/raffle-display">
              <FontAwesomeIcon icon={faDisplay} />&nbsp;
                <span className="shop-menu-ttl">Raffle Display</span>
              </a>
            </li>
            <li>
              <a href="/soiree2023/pledges">
              <FontAwesomeIcon icon={faHandHoldingUsd} />&nbsp;
                <span className="shop-menu-ttl">Pledges</span>
              </a>
            </li>
            <li>
              <a href="/soiree2023/attendees">
              <FontAwesomeIcon icon={faPeopleGroup} />&nbsp;
                <span className="shop-menu-ttl">Attendees</span>
              </a>
            </li>
            <li>
              <a href="/soiree2023/dashboard">
              <FontAwesomeIcon icon={faChartBar} />&nbsp;
                <span className="shop-menu-ttl">Dashboard</span>
              </a>
            </li>
            <li>
              <a href="/soiree2023/reports">
              <FontAwesomeIcon icon={faPrint} />&nbsp;
                <span className="shop-menu-ttl">Print</span>
              </a>
            </li>
            <li>
              <a href="/soiree2023/raffles">
              <FontAwesomeIcon icon={faTicket} />&nbsp;
                <span className="shop-menu-ttl">Raffles</span>
              </a>
            </li>
            <li>
              <a href="/soiree2023/wine">
              <FontAwesomeIcon icon={faWineBottle} />&nbsp;
                <span className="shop-menu-ttl">Wine</span>
              </a>
            </li>
            <li>
              <a href="https://hermes.bigsistersbc.ca/receipts">
              <FontAwesomeIcon icon={faReceipt} />&nbsp;
                <span className="shop-menu-ttl">Receipts</span>
              </a>
            </li>
            {token && <>
              <li>
              <FontAwesomeIcon icon={faEllipsisVertical} style={{color: "#b0b1b3"}} />&nbsp;
                <span className="shop-menu-ttl"></span>
            </li>
            <li className="stylization">
              <a onClick={handleLogout} style={{cursor: "pointer"}}>
              <FontAwesomeIcon icon={faSignOut} />&nbsp;
                <span className="shop-menu-ttl">Log Out</span>
              </a>
            </li>            
            </>}
          </ul>
        </div>
      </div>
    </div>
    </header>
  );
}

export default LogoHeader;