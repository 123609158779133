import React, { Component } from "react";
import { Pie } from "react-chartjs-2";
import axios from "axios";

class AuctionBreakdownChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pie_data: [],
      options: {},
    };
  }

  generateComplementaryColors = (count, baseColor, lightnessOffset = 0.2) => {
    const colors = [];
    const baseHSL = this.rgbToHsl(baseColor);
  
    for (let i = 0; i < count; i++) {
      const hue = (baseHSL.h + (i * 360 / count)) % 360; // Ensuring hue stays within [0, 360)
      const lightness = Math.min(1, baseHSL.l + lightnessOffset); // Ensure lightness doesn't exceed 1
      const hexColor = this.hslToHex({ h: hue, s: baseHSL.s, l: lightness });
      colors.push(hexColor);
    }
  
    return colors;
  };
  
  

  rgbToHsl = (rgbColor) => {
    const r = rgbColor.r / 255;
    const g = rgbColor.g / 255;
    const b = rgbColor.b / 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h,
      s,
      l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }

    return { h: h * 360, s: s, l: l };
  };

  hslToHex = (hslColor) => {
    const { h, s, l } = hslColor;
    const c = (1 - Math.abs(2 * l - 1)) * s;
    const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    const m = l - c / 2;
    const rgb = this.hslToRgb(h, c, x, m);
    return `#${this.rgbToHex(rgb.r)}${this.rgbToHex(rgb.g)}${this.rgbToHex(
      rgb.b
    )}`;
  };

  hslToRgb = (h, c, x, m) => {
    let r, g, b;
    if (h < 60) {
      r = c;
      g = x;
      b = 0;
    } else if (h < 120) {
      r = x;
      g = c;
      b = 0;
    } else if (h < 180) {
      r = 0;
      g = c;
      b = x;
    } else if (h < 240) {
      r = 0;
      g = x;
      b = c;
    } else if (h < 300) {
      r = x;
      g = 0;
      b = c;
    } else {
      r = c;
      g = 0;
      b = x;
    }
    return { r: (r + m) * 255, g: (g + m) * 255, b: (b + m) * 255 };
  };

  rgbToHex = (value) => {
    const hex = Math.round(value).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };


  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/titan-legacy/dashboard/auction`);
      const data = response.data;

      const total = data[0]["Amount"] + data[1]["Amount"];

      const finalFilteredCategories = data.filter(
        (category) => category.Count !== 0 && category.Category !== "Sum"
      );

      const categoryValues = finalFilteredCategories.map(
        (category) => category.Category
      );
      const dataValues = finalFilteredCategories.map(
        (category) => category.Count
      );

      let pie_data_copy = {};
      const baseColor = { r: 252, g: 182, b: 28 };
      let colors_list = this.generateComplementaryColors(
        categoryValues.length,
        baseColor
      );

      pie_data_copy["labels"] = categoryValues;
      pie_data_copy["datasets"] = [];

      pie_data_copy["datasets"].push({
        label: " Amount",
        data: dataValues,
        backgroundColor: colors_list,
        hoverBackgroundColor: colors_list,
      });

      const pie_options = {
        tooltip: {
          callbacks: {
            label: (tooltipItem, data) => {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const label = dataset.label;
              const value = dataset.data[tooltipItem.index];
              return `${label}: $${value.toFixed(2)}`; // Add dollar sign before the value
            },
          },
        },
        plugins: {
          legend: {
            position: "bottom",
          },
        },
        // Set the width and height of the chart
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          },
        },
      };
      

      console.log(pie_data_copy);

      this.setState({ pie_data: pie_data_copy, options: pie_options, total_amount: total });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  render() {
    return (
      <div className="auth-col facts-i">
        <h3 className="facts-i-ttl">
          Auction Winning Bids By Paid Status
        </h3>
        {Object.keys(this.state.pie_data).length > 0 ? (
          <div className="facts-i-percent pie-chart">
            <Pie data={this.state.pie_data} options={this.state.options} />
          </div>
        ) : (
          <p>Loading pie chart...</p>
        )}
      </div>
    );
  }
}

export default AuctionBreakdownChart;
