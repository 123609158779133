import React, { Component } from "react";
import "../css/Wine.css"; // Create a separate CSS file for styling
import LogoHeader from "./LogoHeader";
import Handshake from "../img/handshake.png";
import Spinner from "../Spinner";
import axios from "axios";

class Wine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wines: [],
      attendeeError: "",
      newWine: {
        Identifier: "",
        "Cork Number": "",
      },
      attendee: null,
      error: null,
      isLoading: false,
      screenWidth: 0
    };
  }

  async fetchAttendees() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/brenda-legacy/attendees`
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching attendees:", error);
    }
  }

  async componentDidMount() {
    this.fetchWines();
    this.fetchAttendees();

    this.handleResize = () => {
      this.setState({ screenWidth: window.innerWidth });
    };

    // Add an event listener to update the screenWidth when the window is resized
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    // Clean up the event listener when the component unmounts
    window.removeEventListener("resize", this.handleResize);
  }

  async fetchWines() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/brenda-legacy/wines`);
      const data = await response.json();
      this.setState({ wines: data });
    } catch (error) {
      console.error("Error fetching wines:", error);
    }
  }

  hasEmptyFields(wine) {
    for (const key in wine) {
      if (wine[key] === "") {
        if (key === "Identifier" || key === "Cork Number") {
          return true; // Found an empty field
        }
      }
    }
    return false; // No empty fields found
  }

  addWine = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    

    if (!this.state.newWine["Cork Number"]) {
      this.setState({ error: "Please set the cork number", isLoading: false });
      return;
    }

    if (!this.state.newWine["Identifier"]) {
      this.setState({ error: "Please set the Paddle Number or QR Code", isLoading: false });
      return;
    }
    
    this.setState({ error: "" });

    try {
        const wineToAdd = {
            "Identifier": this.state.newWine["Identifier"],
            "Cork Number": this.state.newWine["Cork Number"],
        };
      const response = await fetch(`${process.env.REACT_APP_API_URL}/brenda-legacy/wines`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(wineToAdd),
      });

      if (response.ok) {
        this.setState({
          newWine: {
            Identifier: "",
            "Cork Number": "",
          },
          attendee: null
        }, ()=> {
          this.fetchWines()
        });
      } else {
        this.setState({ error: "Failed to add wine." });
      }
    } catch (error) {
      this.setState({ error: `Error adding wine: ${error.message}` });
      console.error("Error adding wine:", error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  toCamelCaseName = (inputName) => {
    if (typeof inputName === 'string' && inputName.length > 0) {
      // Split the input name into words using spaces
      const words = inputName.split(" ");
  
      // Capitalize the first letter of each word and convert the rest to lowercase
      const camelCaseName = words
        .map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join(" ");
  
      return camelCaseName;
    } else {
      return ""; // or handle the case of an empty or undefined inputName as needed
    }
  };
  

  handleIdentifierChange = (e) => {
    this.setState({
      newWine: { ...this.state.newWine, Identifier: e.target.value },
    });
  }



  searchForAttendee = async () => {
    try {
      if (!this.state.newWine.Identifier) {
        return;
      }

        // Call fetchAttendees to fetch attendees
        const attendees = await this.fetchAttendees();

        const existingAttendee = attendees.find(
            (attendee) =>
              attendee["Paddle Number"].toString() === this.state.newWine.Identifier ||
              attendee["QR Code"] === this.state.newWine.Identifier
          );

          console.log(existingAttendee);

          if (existingAttendee) {
            this.setState({attendee: existingAttendee, attendeeError: ""});
          }
          else {
            this.setState({
                attendee: null,
                attendeeError: "Unable to locate an attendee based the Identifier. Please check the QR Code or Paddle Number specified in the Indentifier Field"})
          }
    
        // Add your logic here to search for an attendee within this.state.attendees
      } catch (error) {
        this.setState({attendeeError: "Issues loading attendees. Refresh the page and try again."})
      }

  }

  handlePickup = async (event, wineId) => {
    event.preventDefault();

    // Replace 'your-api-endpoint' with the actual API endpoint
    const apiUrl = `${process.env.REACT_APP_API_URL}/brenda-legacy/wines`;

    const requestBody = {
      ID: wineId
    };

    try {
      // Make a PUT request to mark the wine as picked up
      await axios.put(apiUrl, requestBody);
      // Handle a successful response, e.g., show a success message
      console.log("Wine picked up successfully");
      await this.fetchWines();
    } catch (error) {
      // Handle errors, e.g., show an error message
      console.error("Error picking up wine", error);
    }
};

handleKeyPress = (e) => {
  if (e.key === 'Enter') {
    e.preventDefault(); // Prevent the default form submission
    // Handle any other logic you want to perform when Enter is pressed in this field
  }
};

  render() {
    const isIPad = this.state.screenWidth <= 1200;

    return (
      <>
        <LogoHeader />
        <main>
          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>Add a Wine</span>
            </h1>
            <div className="auth-wrap">
              <div className="auth-col">
                <form onSubmit={this.addWine} className="login">
                  <p>
                    <label htmlFor="identifier">
                      Identifier <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="identifier"
                      name="Identifier"
                      value={this.state.newWine.Identifier}
                      onChange={this.handleIdentifierChange}
                      onBlur={this.searchForAttendee}  // Add the onBlur event handler
                      placeholder="Paddle Number or QR Code" 
                      onKeyPress={this.handleKeyPress}
                    />
                  </p>
                  <p>
                    <label htmlFor="cork-number">
                      Cork Number <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="cork_number"
                      name="Cork Number"
                      value={this.state.newWine["Cork Number"]}
                      onChange={(e) =>
                        this.setState({
                          newWine: { ...this.state.newWine, "Cork Number": e.target.value },
                        })
                      }
                      placeholder="Cork numbers separated by commas. For instance: 3, 5, 7"
                    />
                  </p>
                  <p style={{ textAlign: 'right', fontWeight: 'bold' }}>
                    <b>Please input a list of cork numbers separated by commas. For instance: 3, 5, 7</b></p>

                  <p className="auth-submit">
                    <input type="submit" value="Add Wine" disabled={this.state.isLoading} />
                  </p>
                </form>
                {this.state.error && (
                  <div className="err404">
                    <p className="err404-search">{this.state.error}</p>
                  </div>
                )}
              </div>
              <div className="auth-col">
              {this.state.attendee && (
                    <table className="attendee-table">
                    <tbody>
                    <tr>
                        <td>Name:</td>
                        <td>{this.state.attendee.Name}</td>
                    </tr>
                    <tr>
                        <td>Phone Number:</td>
                        <td>{this.state.attendee["Phone Number"]}</td>
                    </tr>
                    <tr>
                        <td>Email:</td>
                        <td>{this.state.attendee.Email}</td>
                    </tr>
                    <tr>
                        <td>QR Code:</td>
                        <td>{this.state.attendee["QR Code"]}</td>
                    </tr>
                    <tr>
                        <td>Paddle Number:</td>
                        <td>{this.state.attendee["Paddle Number"]}</td>
                    </tr>
                    <tr>
                        <td>Table Number:</td>
                        <td>{this.state.attendee["Table Number"]}</td>
                    </tr>
                    </tbody>
                </table>
                )
            }

            {this.state.attendeeError && (
                  <div className="err404">
                    <p className="err404-search">{this.state.attendeeError}</p>
                  </div>)}

      
              </div>
            </div>
          </section>
          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>List of Wines</span>
            </h1>
            <div className="cart-items-wrap">
              <table className="cart-items">
                <thead>
                  <tr>
                    <td className="cart-image"></td>
                    <td className="cart-ttl">Personal</td>
                    <td className="cart-quantity">Paddle Number</td>
                    <td className="cart-quantity">Cork Number</td>
                    <td className="cart-quantity">Picked Up?</td>
                    <td className="cart-del">&nbsp;</td>
                  </tr>
                </thead>
                <tbody>
                  {this.state.wines
                    .slice()
                    .map((wine, index) => (
                      <tr key={index}>
                        <td className="cart-image">
                          <a href="product.html">
                            <img src={Handshake} alt="Handshake" />
                          </a>
                        </td>
                        <td className="cart-ttl">
                          <p>
                          {this.toCamelCaseName(wine["Name"])}{" "}
                          {wine["Name"] && !isIPad && (
                            <span className="small-grey-text">
                              [Name]
                            </span>
                          )}
                          </p>
                          <p>
                          {wine["Phone Number"]}{" "}
                          {wine["Phone Number"] && !isIPad && (
                            <span className="small-grey-text">
                              [Phone Number]
                            </span>
                          )}
                        </p>
                        <p>
                          {wine["Email"]}{" "}
                          {wine["Email"] && !isIPad && (
                            <span className="small-grey-text">[Email]</span>
                          )}
                        </p>
                        </td>
                        <td className="cart-quantity">
                          {wine["Paddle Number"]}
                        </td>
                        <td className="cart-quantity">{wine["Cork Number"]}</td>
                        <td className="cart-quantity">
                        <span style={{ color: wine["hasPickedUp"] ? '#0ba011' : '#e82131' }}>
                              {wine["hasPickedUp"] ? 'Yes' : 'No'}
                            </span>
                        </td>
                      <td className="cart-del">
                            <a
                              className="cart-pickedup"
                              href="/"
                              onClick={(e) => {
                                e.preventDefault(); // Prevent the default link behavior
                                this.handlePickup(e, wine["Cork Number"]);
                              }}
                              role="button" // Add role="button" to indicate it's a button
                            >
                              {"\u00A0"}
                            </a>
                          </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </section>
          {this.state.isLoading && (<Spinner />)}
        </main>
      </>
    );
  }
}

export default Wine;
