import "./css/App.css";
import Pledges from "./Soiree2023/Pledges";
import Home from "./Soiree2023/Home";
import Test from "./Test";
import Attendee from "./Soiree2023/Attendee";
import Dashboard from "./Soiree2023/Dashboard";
import Reports from "./Soiree2023/Reports";
import Raffle from "./Soiree2023/Raffle";
import RaffleDisplay from "./Soiree2023/RaffleDisplay";
import Wine from "./Soiree2023/Wine";
import NotFound from "./NotFound";
import GiftBatches from "./Soiree2023/GiftBatches";
import { Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { default as GrapeJuiceDashboard } from "./GrapeJuice2024/Dashboard";
import { default as GrapeJuiceAttendee } from "./GrapeJuice2024/Attendee";
import { default as GrapeJuicePledges } from "./GrapeJuice2024/Pledges";
import { default as GrapeJuiceAuctionResults } from "./GrapeJuice2024/AuctionResults";
import { default as GrapeJuiceAuction } from "./GrapeJuice2024/Auction";
import AuctionRegistration from "./GrapeJuice2024/AuctionRegistration";
import Experiment from "./GrapeJuice2024/Experiment";
import Auth from "./Auth";
import DashboardHub from "./DashboardHub";

function App() {
  useEffect(() => {
    // Redirect logic
    if (window.location.pathname === "/receipts") {
      window.location.href = "https://hermes.bigsistersbc.ca";
    }
  }, []);

  return (
    <div className="App">
      <Helmet>
        <meta
          http-equiv="Content-Security-Policy"
          content="upgrade-insecure-requests"
        />
      </Helmet>
      <Routes>
        <Route path="/" element={<DashboardHub />} />
        <Route path="/experiment" element={<Experiment />} />
        <Route path="/grapejuice2024" element={<GrapeJuiceDashboard />} />
        <Route path="/grapejuice2024/dashboard" element={<GrapeJuiceDashboard />} />
        <Route path="/grapejuice2024/attendees" element={<GrapeJuiceAttendee />} />
        <Route path="/grapejuice2024/auction-results" element={<GrapeJuiceAuctionResults />} />
        <Route path="/grapejuice2024/auction" element={<GrapeJuiceAuction />} />

        <Route
          path="/grapejuice2024/auction-registration"
          element={<AuctionRegistration />}
        />
        <Route path="/grapejuice2024/pledges" element={<GrapeJuicePledges />} />

        <Route path="/soiree2023" element={<Dashboard />} />
        <Route path="/soiree2023/admin" element={<Pledges />} />
        <Route path="/soiree2023/test" element={<Test />} />
        <Route path="/soiree2023/attendees" element={<Attendee />} />
        <Route path="/soiree2023/dashboard" element={<Dashboard />} />
        <Route path="/soiree2023/pledges" element={<Pledges />} />
        <Route path="/soiree2023/reports" element={<Reports />} />
        <Route path="/soiree2023/raffles" element={<Raffle />} />
        <Route path="/soiree2023/wine" element={<Wine />} />
        <Route path="/soiree2023/batching" element={<GiftBatches />} />
        <Route path="/soiree2023/raffle-display" element={<RaffleDisplay />} />

        <Route path="/login" element={<Auth />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;